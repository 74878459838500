<template>
    <div>
        <div class="pageheader pd-t-25 pd-b-15">
            <div class="pd-t-5 pd-b-5">
                <h1 class="pd-0 mg-0 tx-20 text-overflow">Riwayat Hapus Data</h1>
            </div>
            <div class="breadcrumb pd-0 mg-0">
                <a class="breadcrumb-item" href="#"><i class="fa fa-clock-o mg-r-5"></i>Riwayat Hapus Data</a>
            </div>
        </div> 

        <!-- MAIN CONTENT ================================================== -->
        <div class="row justify-content-center">
            <div class="col-12 col-lg-12 col-xl-12">
                
                <!-- CONTENT -->
                <div class="tab-content mb-3">
                    <div class="tab-pane show active" id="anggota-tab" role="tabpanel">
                        <div class="card">
                            <div class="card-header">
                                <div class="input-group input-group-flush input-group-merge">
                                    <input type="search" class="form-control form-control-prepended search" v-model="keywords" v-on:keyup.enter="getData()" placeholder="Cari Data...">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <span class="fe fe-search"></span>
                                        </div>
                                    </div>

                                    <input  type="date" class="form-control" style="width:30px !important" required=""
                                                v-model="form.start_date" placeholder="Tanggal Awal">
                                    <input  type="date" class="form-control" style="width:30px !important" required=""
                                                v-model="form.end_date" placeholder="Tanggal Akhir">

                                    <button type="button" v-on:click="getData()" class=" btn btn-secondary"><i class="fa fa-filter"></i></button>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table border=1 class="m-2 table table-sm table-striped  table-responsive-sm">
                                    <thead>
                                        <tr>
                                            <th class="text-center" style="width:4%">No</th>
                                            <th class="text-center">Transaksi</th>
                                            <th class="text-center">No Dokumen</th>
                                            <th class="text-center">No Bukti</th>
                                            <th class="text-center">Pelaku</th>
                                            <th class="text-center">SKPD</th>
                                            <th class="text-center">Waktu</th>
                                         </tr>
                                    </thead>
                                    <tbody v-if="listData.length > 0">
                                        <tr v-for="(item, index) in listData" :key="index">
                                            <td class="text-center">{{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}</td>
                                            <td>{{ item.type }}</td>
                                            <td>{{ item.no_dokumen }}</td>
                                            <td>{{ item.no_bukti }}</td>
                                            <td>{{ item.pelaku }}</td>
                                            <td>{{ item.nickname }}</td>
                                            <td>{{ item.created_at }}</td>
                                        </tr>
                                    </tbody>
                                    <tbody v-if="listData.length == 0">
                                        <tr>
                                            <td colspan="7" class="text-center">-- Belum Ada Data --</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer border-top-0">
                                <div style="float:right">
                                    <!-- <ul class="pagination mb-0"></ul> -->
                                    <v-pagination v-if="pagination.totalPages > 0" v-model="pagination.currentPage"
                                                :page-count="pagination.totalPages"
                                                :classes="pagination.bootstrapPaginationClasses"
                                                :labels="pagination.paginationAnchorTexts"
                                                v-on:change="getData()"></v-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- ADD SPACE -->
                <br><br><br>
            </div>
        </div>
        <!-- END MAIN CONTENT -->
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import vPagination from 'vue-plain-pagination'

    import Vue from 'vue'
    import VueMask from 'v-mask'
    Vue.use(VueMask);


    import createNumberMask from 'text-mask-addons/dist/createNumberMask';
    const currencyMask = createNumberMask({
        prefix: '',
        allowDecimal: true,
        decimalLimit: 7,
        includeThousandsSeparator: true,
        allowNegative: false,
    });
    export default {
        metaInfo: {
            title: 'SI',
            titleTemplate: '%s - Data Persediaan'
        },
        data() {
            return {
                baseUrl: this.$apiconfig,
                tokenFix: localStorage.auth_token,
                currencyMask,
                listData: [],
                fetching: true,
                form: {
                    start_date: '',
                    end_date: '',
                },
                pagination: {         
                    currentPage: 1,
                    totalPages: 0,
                    limit: 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'  
                    },
                    paginationAnchorTexts: {
                        first: '<i class="fe fe-chevrons-left"></i>',
                        prev: '<i class="fe fe-chevron-left"></i>',
                        next: '<i class="fe fe-chevron-right"></i>',
                        last: '<i class="fe fe-chevrons-right"></i>'
                    }
                },
                keywords: '',
                showSelectYear: true
                
            }
        },
        created() {
            this.$store.dispatch('loadUserData');
            this.getData();
        },
        computed: {
            ...mapState(['userdata']),
        },
        components: {
            vPagination,
        },
        methods: {
            getData() {
                this.fetching = true;
                this.$http.get(this.baseUrl + 'admin/history', {
                    params: {
                        page : this.pagination.currentPage,
                        keywords: this.keywords,
                        start: this.form.start_date,
                        end: this.form.end_date,
                    }
                })
                    .then((response) => {
                        this.listData = response.data.data;
                        this.form.start_date = response.data.start;
                        this.form.end_date   = response.data.end;
                        this.fetching = false;
                        this.pagination.totalPages = response.data.total_page;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
            },
        },
    };
</script>
<style>
    /* style sweetalert */
    .swal2-html-container {
        display: block;
        text-align: center;
    }
</style>